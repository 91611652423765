/// <reference lib="webworker" />

import "./lib/std"
import { tracing } from "./lib/tracing"

let trace = tracing("Service Worker")
trace.debug("Hey, I am a serviceworker!", self)

self.addEventListener("online", () => trace.debug("online"))
self.addEventListener("message", onMessage)

async function onMessage(ev: MessageEvent, msg = ev.data) {

}
